<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">REPORTES</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">GANANCIAS VENTAS</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span style="border-bottom: 1px solid #005f91; font-size: 1.5rem">
        <strong>INFORME GANANCIAS VENTAS</strong>
      </span>
    </div>
    <div class="col-12 p-fondo">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <span><strong>SUCURSAL: </strong></span>
          <Dropdown
            v-model="sucursalSelected"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-9">
          <span><strong>NOMBRE PRODUCTO: </strong></span>
          <AutoComplete
            :dropdown="true"
            field="descripcion"
            v-model="productoSelected"
            :suggestions="productosListadoFiltrado"
            @complete="buscarProducto($event)"
            placeholder="Escriba Nombre Producto"
          >
            <template #item="slotProps">
              <div>
                <strong>{{ slotProps.item.descripcion }}</strong
                ><span
                  ><strong> --- </strong
                  ><!-- </span>
                      {{ slotProps.item.fabricas.nombre_proveedor
                      }}<span><strong> ---</strong> --></span
                >
                {{ slotProps.item.fabrica_nombre }}
              </div>
            </template>
          </AutoComplete>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>USUARIOS/CAJEROS</strong></span>
          <Dropdown
            v-model="userSeleccionado"
            :options="users"
            optionLabel="name"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>FECHA VENTA DESDE: </strong></span>
          <Calendar
            v-model="fecha_venta_desde"
            :showIcon="true"
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>FECHA VENTA HASTA: </strong></span>
          <Calendar
            v-model="fecha_venta_hasta"
            :showIcon="true"
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="field col-12 md:col-3" v-show="false">
          <span><strong>SELECCIONE MES: </strong></span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="mes_seleccionado"
              :options="meses"
              optionLabel="label"
              placeholder="Seleccione un mes"
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>ACCIONES: </strong></span>
          <Button
            label="APLICAR FILTROS"
            icon="pi pi-search"
            class="p-button-info"
            v-tooltip.top="'Aplicar Filtros de Busqueda'"
            :loading="enviando"
            :disabled="enviando"
            @click="BuscarFiltro"
          />
        </div>
      </div>
    </div>
    <div class="mt-2 style_card">
      <div class="p-fluid formgrid grid">
        <table class="table table-bordered table-striped" style="width: 100%">
          <tr>
            <th class="text-center">
              TOTAL VENTAS:
              <span class="p-badge p-badge-blue p-mr-2" style="font-size: 1rem"
                >{{
                  convertirNumeroGermanicFormat(total_ventas ?? 0)
                }}
                Bs.</span
              >
            </th>
            <th class="text-center">
              TOTAL COMPRAS:
              <span class="p-badge p-badge-blue p-mr-2" style="font-size: 1rem"
                >{{
                  convertirNumeroGermanicFormat(total_compras ?? 0)
                }}
                Bs.</span
              >
            </th>
            <th class="text-center">
              VENTAS CR&Eacute;DITO:
              <span
                class="p-badge p-badge-danger p-mr-2"
                style="font-size: 1rem"
                >{{
                  convertirNumeroGermanicFormat(ventas_ganancias_creditos ?? 0)
                }}
                Bs.</span
              >
            </th>
            <th class="text-center">
              TOTAL DESCUENTOS:
              <span
                class="p-badge p-badge-danger p-mr-2"
                style="font-size: 1rem"
                >{{
                  convertirNumeroGermanicFormat(total_ventas_descuentos ?? 0)
                }}
                Bs.</span
              >
            </th>
            <th class="text-center">
              GANANCIAS VENTAS:
              <span
                class="p-badge p-badge-success p-mr-2"
                style="font-size: 1rem"
                >{{
                  convertirNumeroGermanicFormat(total_ganancias_ventas)
                }}
                Bs.</span
              >
            </th>
          </tr>
        </table>
      </div>
    </div>
    <div class="mt-2">
      <DataTable
        ref="reporte_ganancias_ventas"
        key="id"
        :value="ventas_ganancias"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar..."
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                v-if="'R_Ganancias DescargarPDF' in auth.user.permissions"
                label="PDF Imprimir"
                icon="pi pi-file-pdf"
                class="p-button-outlined p-button-danger mr-2"
                v-tooltip.top="'Imprimir Reporte PDF'"
                :loading="generandoPDF"
                :disabled="generandoPDF"
                @click="generarPDF"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                v-if="'R_Ganancias DescargarEXCEL' in auth.user.permissions"
                label="EXCEL Exportar"
                icon="pi pi-file-excel"
                class="p-button-outlined p-button-success"
                v-tooltip.top="'Imprimir Reporte Excel'"
                :loading="exportando"
                :disabled="exportando"
                @click="exportVentas"
              />
            </span>
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >Debe Aplicar Filtros para ver Resultados!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="id_venta" header="#VENTA"> </Column>
        <Column field="fecha_venta" header="F.VENTA"> </Column>
        <Column
          field="nombre_sucursal"
          header="SUCURSAL"
          v-if="sucursales.length > 2"
        >
          {{ data.sucursal }}
        </Column>
        <Column field="nombre_usuario" header="USUARIO">
          {{ data.usuario }}
        </Column>

        <Column field="nombre_laboratorio" header="MARCA" :sortable="true">
        </Column>
        <Column
          field="nombre_producto"
          header="PRODUCTO"
          style="font-weight: bold"
        ></Column>
        <Column field="cantidad" header="CANTIDAD" class="text-right"> </Column>
        <Column field="precio_compra" header="T.COMP." class="text-right">
          <template #body="{ data }">
            {{
              convertirNumeroGermanicFormat(
                obtenerTotalCompra(data.precio_compra, data.cantidad)
              )
            }}
          </template>
        </Column>
        <Column field="descuento_neto" header="DESC." class="text-right">
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.descuento_neto) }}
          </template>
        </Column>
        <Column
          field="precio_venta"
          header="T.VENTA"
          class="text-right"
          :sortable="true"
        >
          <template #body="{ data }">
            {{
              convertirNumeroGermanicFormat(
                obtenerTotalVenta(
                  data.precio_venta,
                  data.cantidad,
                  data.descuento_neto
                )
              )
            }}
          </template>
        </Column>
        <Column
          field="ganancia_total"
          header="T.GAN."
          class="text-right"
          :sortable="true"
        >
          <template #body="{ data }">
            {{
              convertirNumeroGermanicFormat(
                obtenerTotalGanancia(
                  data.precio_venta,
                  data.precio_compra,
                  data.cantidad,
                  data.descuento_neto
                )
              )
            }}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import VentaService from "@/service/VentaService";
import SucursalService from "@/service/SucursalService";
import UserService from "@/service/UserService";
import ProductService from "@/service/ProductService";
import { useAuth } from "@/stores";
import { sum } from "lodash";

export default {
  data() {
    return {
      fecha_venta_desde: null,
      fecha_venta_hasta: null,
      enviando: false,
      ventas_ganancias: [],
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      userSeleccionado: 0,
      users: [{ id: 0, name: "TODOS" }],
      generandoPDF: false,
      exportando: false,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      productoSelected: null,
      productosListadoFiltrado: [],
      mes_seleccionado: null,
      meses: [
        { label: "ENERO", value: 1 },
        { label: "FEBRERO", value: 2 },
        { label: "MARZO", value: 3 },
        { label: "ABRIL", value: 4 },
        { label: "MAYO", value: 5 },
        { label: "JUNIO", value: 6 },
        { label: "JULIO", value: 7 },
        { label: "AGOSTO", value: 8 },
        { label: "SEPTIEMBRE", value: 9 },
        { label: "OCTUBRE", value: 10 },
        { label: "NOVIEMBRE", value: 11 },
        { label: "DICIEMBRE", value: 12 },
      ],
      mostrarModal: false,
      ventas_ganancias_creditos: 0,
    };
  },
  ventaService: null,
  sucursalService: null,
  userService: null,
  auth: null,
  productService: null,
  created() {
    this.ventaService = new VentaService();
    this.sucursalService = new SucursalService();
    this.userService = new UserService();
    this.auth = useAuth();
    this.productService = new ProductService();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarUsuarios();
  },
  computed: {
    total_ventas_descuentos() {
      let total = 0;
      this.ventas_ganancias.forEach((item) => {
        total += parseFloat(item.descuento_neto);
      });
      return total;
    },
    total_ventas() {
      return sum(
        this.ventas_ganancias.map((item) => {
          return item.precio_venta * item.cantidad;
        })
      );
    },
    total_compras() {
      return sum(
        this.ventas_ganancias.map((item) => {
          return item.precio_compra * item.cantidad;
        })
      );
    },
    /* total_ventas_credito() {
      return sum(
        this.ventas_ganancias.map((item) => {
          return parseFloat(item.saldo);
        })
      );
    }, */
    total_ganancias_ventas() {
      return (
        this.total_ventas -
        this.total_compras -
        this.ventas_ganancias_creditos -
        this.total_ventas_descuentos
      );
    },
  },
  methods: {
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({
            texto: event.query,
            sucursal_id: this.sucursalSelected,
          })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    obtenerTotalVenta(precio_venta, cantidad, descuento_neto) {
      return precio_venta * cantidad - descuento_neto;
    },
    obtenerTotalCompra(precio_compra, cantidad) {
      return precio_compra * cantidad;
    },
    obtenerTotalGanancia(
      precio_venta,
      precio_compra,
      cantidad,
      descuento_neto
    ) {
      return (
        precio_venta * cantidad - precio_compra * cantidad - descuento_neto
      );
    },
    exportVentas() {
      if (this.ventas_ganancias.length > 0) {
        let datos = {
          sucursal_id: this.sucursalSelected,
          cajero_id: this.userSeleccionado,
          fecha_venta_desde: this.fecha_venta_desde,
          fecha_venta_hasta: this.fecha_venta_hasta,
          producto: this.productoSelected ? this.productoSelected.id : null,
        };
        this.ventaService.exportarRGananciasVentas(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Exportación exitosa",
            detail: "Se ha exportado correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    generarPDF() {
      if (this.ventas_ganancias.length > 0) {
        this.generandoPDF = true;
        let datos = {
          sucursal_id: this.sucursalSelected,
          cajero_id: this.userSeleccionado,
          fecha_venta_desde: this.fecha_venta_desde,
          fecha_venta_hasta: this.fecha_venta_hasta,
          producto: this.productoSelected ? this.productoSelected.id : null,
          mes_seleccionado: this.mes_seleccionado
            ? this.mes_seleccionado.value
            : null,
        };
        this.ventaService.imprimirRGananciasPDF(datos).then(() => {
          this.generandoPDF = false;
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: "Se genero el PDF correctamente",
            life: 3000,
          });
        });
      } else
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
    },
    cargarUsuarios() {
      this.userService.getUsersAll().then((data) => {
        data.forEach((element) => {
          this.users.push(element);
        });
      });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((data) => {
          data.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    BuscarFiltro() {
      /* alert("AQUÍ PODRÁS VER LA GANANCIA DE CADA PRODUCTO VENDIDO, ESTAMOS TRABAJANDO EN ESTA PARTE"); */
      this.ventas_ganancias = [];
      this.enviando = true;
      this.cargarReporteVentas();
    },
    cargarReporteVentas() {
      let datos = {
        sucursal_id: this.sucursalSelected,
        cajero_id: this.userSeleccionado,
        fecha_venta_desde: this.fecha_venta_desde,
        fecha_venta_hasta: this.fecha_venta_hasta,
        producto: this.productoSelected ? this.productoSelected.id : null,
        mes_seleccionado: this.mes_seleccionado
          ? this.mes_seleccionado.value
          : null,
      };
      this.ventaService
        .filtrarGananciasVentas(datos)
        .then((data) => {
          this.ventas_ganancias = data.ventas_ganancias ?? [];
          this.ventas_ganancias_creditos = data.ventas_ganancias_creditos ?? 0;
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid #002236;
  padding: 10px;
}

.style_card {
  border: 1px solid #000000;
  border-bottom: 5px solid #000000;
}
</style>
